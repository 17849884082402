<script lang="ts" context="module">
  export function isMac(): boolean {
    if (!globalThis.navigator) {
      return false; // SSR
    }
    return navigator.userAgent.toLowerCase().includes("mac");
  }
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let hotKey: string;

  // Ctrl on Windows and Cmd on macOS
  export let ctrlOrCmd: boolean = false;

  // Shift key
  export let shift: boolean = false;

  // Alt key
  export let alt: boolean = false;

  const dispatch = createEventDispatcher<{ hotkey: void }>();

  function handleKeyPress(event: KeyboardEvent) {
    const ctrlOrCmdValue = isMac() ? event.metaKey : event.ctrlKey;
    if (
      event.key === hotKey &&
      ctrlOrCmdValue === ctrlOrCmd &&
      event.shiftKey === shift &&
      event.altKey === alt
    ) {
      event.preventDefault();
      dispatch("hotkey");
    }
  }
</script>

<svelte:window on:keydown={handleKeyPress} />
